@import "./types.scss";

$shine-color-default: rgba(148, 148, 148, 0.452);

.inner-card {
    width: 100%;
    padding: 10px;
    position: relative;
    z-index: 1;
    border: #000000;

    &:before {
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: linear-gradient(135deg, transparent 0%, $shine-color-default 25%, transparent 50%, $shine-color-default 75%, transparent 100%);
      transition: transform 1s ease;
      z-index: -1;
      opacity: 0.8;
    }
  
    &:hover {
      transform: translateY(0);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  
      .variety-dropdown {
        border-color: #494949;
        background-color: #ffffff;
      }
    }
  
    &:hover:before {
      transform: translate(25%, 25%);
    }
  
    &:not(:hover):before {
      transform: translate(0, 0);
    }

    @import "./type-backgrounds.scss";
  }
