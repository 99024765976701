@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro&display=swap:100,300,400,700");
@import "css/types.scss";

body {
  background-color: #d2dbdd;
}

* {
  font-family: "Source Code Pro";
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.card-area {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: center;
}

input {
  margin: 10px;
}

.Card {
  position: relative;
  box-sizing: border-box;
  width: 250px;
  display: flex;
  justify-content: center;
  max-width: 250px;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 5px 13px 10px -7px rgba(0, 0, 0, 0.1);
  margin: 2px;
  transition: transform 1s ease, border 1s ease;
  z-index: 1;
  border: 3px solid #00000046;

  @import "css/inner-card.scss";

  &:hover {
    transform: translateY(-10px);
    box-shadow:  0 10px 20px rgba(0, 0, 0, 0.1);
    border: 3px solid #00000094;

    .pokedex-number {
      color: rgb(17, 17, 17);
    }
  }

  .variety-dropdown {
    width: 70%;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    background-color: #00000000;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
}

.pokemonImage {
  height: 100px;
  object-fit: scale-down;
  object-position: bottom;
}

h4 {
  text-transform: capitalize;
  display: inline-block;
}

img {
  margin: auto;
  display: block;
}

.type {
  margin-left: 10px;
  margin-bottom: 1%;
  padding: 5px 8px;
  display: inline-block;
  border-radius: 6px;
  font-size: 13px;
  font-family: "helvetica neue", Helvetica, Verdana, sans-serif;
  color: #fff;
  background-color: #68a090;
  border: 1px solid #44685e;

  &.normal {
    background-color: $normal;
    border: 1px solid #6d6d4e;
  }

  &.fire {
    background-color: $fire;
    border: 1px solid #9c531f;
  }

  &.water {
    background-color: $water;
    border: 1px solid #445e9c;
  }

  &.electric {
    background-color: $electric;
    border: 1px solid #a1871f;
  }

  &.grass {
    background-color: $grass;
    border: 1px solid #4e8234;
  }

  &.ice {
    background-color: $ice;
    border: 1px solid #638d8d;
  }

  &.ground {
    background-color: $ground;
    border: 1px solid #927d44;
  }

  &.flying {
    background-color: $flying;
    border: 1px solid #6d5e9c;
  }

  &.ghost {
    background-color: $ghost;
    border: 1px solid #493963;
  }

  &.rock {
    background-color: $rock;
    border: 1px solid #786824;
  }

  &.fighting {
    background-color: $fighting;
    border: 1px solid #7d1f1a;
  }

  &.poison {
    background-color: $poison;
    border: 1px solid #682a68;
  }

  &.psychic {
    background-color: $psychic;
    border: 1px solid #a13959;
  }

  &.bug {
    background-color: $bug;
    border: 1px solid #6d7815;
  }

  &.dark {
    background-color: $dark;
    border: 1px solid #49392f;
  }

  &.steel {
    background-color: $steel;
    border: 1px solid #787887;
  }

  &.dragon {
    background-color: $dragon;
    border: 1px solid #4924a1;
  }
  
  &.fairy {
    background-color: $fairy;
    border: 1px solid #4924a1;
  }
}

.gender-icon,
.rotate-icon,
.shiny-icon {
  position: absolute;
  right: 10px;
}

.gender-icon { top: 145px; }
.rotate-icon { top: 110px; }
.shiny-icon { top: 70px; }

.hidden-button {
  font-size: 0;
  background-color: transparent;
  border: none;
  padding: 0;
}

.gendered-icon:hover,
.rotation-icon:hover,
.button-icon:hover,
.shiny-disabled-icon:hover {
  color: #5f5f5f;
}

.button-icon {
  cursor: default;
  font-size: 20px;
  color: #999;
}

.shiny-disabled-icon {
  font-size: 18px;
  color: #999;
}

.shiny-enabled-icon {
  font-size: 18px;
  color: #ffcc00;

  &:hover {
    color: #c9a100;
  }
}

.icon-right {
  font-size: 24px; /* Set icon size */
  color: #999; /* Icon color */

  &:hover {
    color: #333; /* Darkens on hover */
  }
}

.iconLeft {
  margin-right: 50pt;
}

.search-bar {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-bar:focus {
  border-color: #66afe9;
}

.search-bar::placeholder {
  color: #999;
}

.pokemon-name::first-letter {
  text-transform: capitalize;
}

.pokedex-number {
  font-weight: bold;
  color: rgb(175, 175, 175);
  position: absolute;
  top: 10px;
  left: 0;
  margin-left: 10px;
  transition: color 1s ease;
}

.details-area {
  left: 0;
  margin-top: 4px;

  .pokemon-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1px;
  
    .detail {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    
    p {
      color: #202020;
      font-family: 'Courier New', Courier, monospace;
      font-size: 11pt;
      line-height: 0.7;
      margin: 0.1em 0;
      padding-top: 5px;
      border-bottom: 1px dotted #c4c4c4;
      width: 100%;
  
      &.statistic {
        text-align: left;
      }
  
      &.value {
        text-align: right;
      }
    }
  }
}

.variety-container {
  height: 25px;
}