$normal: #a8a878;
$fire: #f08030;
$water: #6890f0;
$electric: #f8d030;
$grass: #78c850;
$ice: #98d8d8;
$ground: #e0c068;
$flying: #a890f0;
$ghost: #705898;
$rock: #b8a038;
$fighting: #c03028;
$poison: #a040a0;
$psychic: #f85888;
$bug: #a8b820;
$dark: #705848;
$steel: #b8b8d0;
$dragon: #7038f8;
$fairy: #f196db;
